// Pages/ComplaintBoardPage.js
import React from 'react';
import './ComplaintBoardPage.css';

function ComplaintBoardPage() {
  // Example data for the table
  const data = [
    {
      srNo: 1,
      receivedFrom: 'SEBI (SCORES)',
      pendingAtEndOfLastMonth: 0,
      received: 0,
      receivedStar: 0,
      totalPending: 0,
      pendingComplaintOver3Months: 0,
      avgResolutionTime: 0,
    },
    {
      srNo: 2,
      receivedFrom: 'SMART ODR',
      pendingAtEndOfLastMonth:4,
      received: 263,
      receivedStar: 259,
      totalPending: 4,
      pendingComplaintOver3Months: 2,
      avgResolutionTime: 30,
    },
    {
      srNo: "",
      receivedFrom: 'Grand Total',
      pendingAtEndOfLastMonth: 2,
      received: 2,
      receivedStar: 0,
      totalPending: 2,
      pendingComplaintOver3Months: 0,
      avgResolutionTime: "0",
    },
  ];





   // Example data for the "Trend of Monthly Disposal of Complaints" table
   const trendData = [
    {
      srNo: 1,
      month: 'DEC-23',
      carriedForwardFromPreviousMonth: 0,
      received: 0,
      receivedStar: 0,
      pending: 0,
    },

    {
      srNo: 2,
      month: 'Jan-24',
      carriedForwardFromPreviousMonth: 0,
      received: 0,
      receivedStar: 0,
      pending: 0,
    },
    {
      srNo: 3,
      month: 'Feb-24',
      carriedForwardFromPreviousMonth: 0,
      received: 2,
      receivedStar: 0,
      pending: 2,
    },
    {
      srNo: 4,
      month: 'Mar-24',
      carriedForwardFromPreviousMonth: 2,
      received: 2,
      receivedStar: 3,
      pending: 1,
    },
    {
      srNo: 5,
      month: 'Apr-24',
      carriedForwardFromPreviousMonth: 1,
      received: 10,
      receivedStar: 2,
      pending: 9,
    },
    {
      srNo: 6,
      month: 'May-24',
      carriedForwardFromPreviousMonth: 9,
      received: 5,
      receivedStar: 4,
      pending: 10,
    },

    {
      srNo: 7,
      month: 'Jun-24',
      carriedForwardFromPreviousMonth: 10,
      received: 0,
      receivedStar: 4,
      pending: 6,
    },
    {
      srNo: 8,
      month: 'Jul-24',
      carriedForwardFromPreviousMonth: 6,
      received: 3,
      receivedStar: 3,
      pending: 6,
    },
    {
      srNo: 9,
      month: 'Aug-24',
      carriedForwardFromPreviousMonth: 6,
      received: 9,
      receivedStar: 3,
      pending: 12,
    },
    {
      srNo: 10,
      month: 'Sep-24',
      carriedForwardFromPreviousMonth: 12,
      received: 235,
      receivedStar: 9,
      pending: 238,
    },
    {
      srNo: 11,
      month: 'Oct-24',
      carriedForwardFromPreviousMonth: 238,
      received: 3,
      receivedStar: 235,
      pending: 6,
    },
    {
      srNo: 12,
      month: 'Nov-24',
      carriedForwardFromPreviousMonth: 238,
      received: 3,
      receivedStar: 235,
      pending: 6,
    },
  ];



   // Example data for the "Trend of Monthly Disposal of Complaints" table
   const trendAnnualData = [


    {

      srNo: 1,
      year: '2023-2024',
      carriedForwardFromPreviousMonth: 0,
      received: 4,
      receivedStar: 3,
      pending: 1,
    },
    
    {

      srNo: 1,
      year: '2024-2025',
      carriedForwardFromPreviousMonth: 0,
      received: 265,
      receivedStar: 259,
      pending: 6,
    },
   ]










     // Example data for the table
  const dataCurrentMonth = [
    {
      srNo: 1,
      receivedFrom: 'Directly from Investors',
      pendingAtEndOfLastMonth: 0,
      received: 0,
      receivedStar: 0,
      totalPending: 0,
      pendingComplaintOver3Months: 0,
      avgResolutionTime: "N/A",
    },
    {
      srNo: 2,
      receivedFrom: 'SEBI (SCORES)',
      pendingAtEndOfLastMonth:0,
      received: 0,
      receivedStar: 0,
      totalPending: 0,
      pendingComplaintOver3Months: 0,
      avgResolutionTime: "N/A",
    },
    {
      srNo: "",
      receivedFrom: 'Grand Total',
      pendingAtEndOfLastMonth: 0,
      received: 0,
      receivedStar: 0,
      totalPending: 0,
      pendingComplaintOver3Months: 0,
      avgResolutionTime: "N/A",
    },
  ];

  return (
    <div className="complaint-board-page">
      <h1>Complaint Board for October 2024</h1>
      <div className="table-wrapper">
      <table className="complaint-table">
        <thead>
          <tr>
            <th>Sr No</th>
            <th>Received From</th>
            <th>Pending at end of last month</th>
            <th>Received</th>
            <th>Received*</th>
            <th>Total Pending #</th>
            <th>Pending Complaint{'>'}3 month</th>
            <th>Average Resolution Time (in days)</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.srNo}>
              <td>{item.srNo}</td>
              <td>{item.receivedFrom}</td>
              <td>{item.pendingAtEndOfLastMonth}</td>
              <td>{item.received}</td>
              <td>{item.receivedStar}</td>
              <td>{item.totalPending}</td>
              <td>{item.pendingComplaintOver3Months}</td>
              <td>{item.avgResolutionTime}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>



       {/* Second Table: Trend of Monthly Disposal of Complaints */}
       <h2>Trend of Monthly Disposal of Complaints</h2>
       <div className="table-wrapper">
      <table className="trend-table">
        <thead>
          <tr>
            <th>Sr No</th>
            <th>Month</th>
            <th>Carried Forward from Previous Month</th>
            <th>Received</th>
            <th>Received*</th>
            <th>Pending #</th>
          </tr>
        </thead>
        <tbody>
          {trendData.map((item) => (
            <tr key={item.srNo}>
              <td>{item.srNo}</td>
              <td>{item.month}</td>
              <td>{item.carriedForwardFromPreviousMonth}</td>
              <td>{item.received}</td>
              <td>{item.receivedStar}</td>
              <td>{item.pending}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>




       {/* Second Table: Trend of Monthly Disposal of Complaints */}
       <h2>Trend of Annual Disposal of Complaints</h2>
       <div className="table-wrapper">
      <table className="trend-table">
        <thead>
          <tr>
            <th>Sr No</th>
            <th>Year</th>
            <th>Carried Forward from Previous Month</th>
            <th>Received</th>
            <th>Received*</th>
            <th>Pending #</th>
          </tr>
        </thead>
        <tbody>
          {trendAnnualData.map((item) => (
            <tr key={item.srNo}>
              <td>{item.srNo}</td>
              <td>{item.year}</td>
              <td>{item.carriedForwardFromPreviousMonth}</td>
              <td>{item.received}</td>
              <td>{item.receivedStar}</td>
              <td>{item.pending}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>







      
             {/* Second Table: Trend of Monthly Disposal of Complaints */}
             <h2>For Current Month</h2>
       <div className="table-wrapper">
      <table className="trend-table">
        <thead>
          <tr>
            <th>Sr No</th>
            <th>Received From</th>
            <th>Pending at end of Last Month</th>
            <th>Received </th>
            <th>Resolved</th>
            <th>Total Pending</th>
            <th>Total Complaints</th>
            <th>Average Resolution Time</th>
          
          </tr>
        </thead>
        <tbody>
          {dataCurrentMonth.map((item) => (
            <tr key={item.srNo}>
              <td>{item.srNo}</td>
              <td>{item.receivedFrom}</td>
              <td>{item.pendingAtEndOfLastMonth}</td>
              <td>{item.received}</td>
              <td>{item.receivedStar}</td>
              <td>{item.totalPending}</td>
              <td>{item.receivedStar}</td>
              <td>{item.avgResolutionTime}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>





      



    </div>
  );
}

export default ComplaintBoardPage;
