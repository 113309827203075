import React, { useState } from "react";
import "./DisclosureComponent.css";

const DisclosureComponent = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleSection = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const sections = [
    {
      title: "1) History, Present Business and Background",
      content: (
        <>
          Sameer Satish Mekde is registered with SEBI as Research Analyst with
          Registration No. INH000010946. The Research Analyst got its registration
          on November 23, 2022, and is engaged in offering research and recommendation
          services.
        </>
      ),
    },
    {
      title: "2) Terms and Conditions of Research Services",
      content: (
        <>
          The Research Services will be limited to providing independent research
          recommendation and shall not be involved in any advisory or portfolio
          allocation services.
          <br />
          The Research Analyst never guarantees the returns on the recommendation
          provided. Investors should take note that Investment/trading in stocks/Index
          or other securities is always subject to market risk. Past performance is never
          a guarantee of same future results.
          <br />
          The Research Analyst shall not be responsible for any loss to the Investors.
        </>
      ),
    },
    {
      title: "3) Disciplinary History",
      content: (
        <>
          There are no pending material litigations or legal proceedings against the
          Research Analyst.
          <br />
          As of today, no penalties/directions have been issued by SEBI under the SEBI
          Act or Regulations made there under against the Research Analyst relating to
          Research Analyst services.
        </>
      ),
    },
    {
      title: "4) Details of its Associates",
      content: "No associates.",
    },
    {
      title: "5) Disclosures with Respect to Research and Recommendations Services",
      content: (
        <>
          <ul>
            <li>The Research Analyst or any of its officer/employee does not trade in securities which are subject matter of recommendation.</li>
            <li>There are no actual or potential conflicts of interest arising from any connection to or association with any issuer of products/securities.</li>
            <li>The Research Analyst or its employees have not received any compensation from the company which is subject matter of recommendation.</li>
            <li>Research Analyst has not managed or co-managed the public offering of any company.</li>
            <li>Research Analyst has not received any compensation or other benefits from the Subject Company or third party in connection with the research report/recommendation.</li>
            <li>The subject company was not a client of Research Analyst or its employee or its associates during twelve months preceding the date of recommendation services provided.</li>
            <li>Research Analysts have not served as an officer, director, or employee of the subject company.</li>
            <li>Research Analyst has not been engaged in market making activity of the subject company.</li>
          </ul>
        </>
      ),
    },
    {
      title: "6) Standard Warning",
      content: (
        <p>
          “Investment in securities market are subject to market risks. Read all the related
          documents carefully before investing.”
        </p>
      ),
    },
    {
      title: "7) Disclaimer",
      content: (
        <p>
          “Registration granted by SEBI, and certification from NISM in no way guarantee
          performance of the Research Analyst or provide any assurance of returns to investors.”
        </p>
      ),
    },
  ];

  return (
    <div className="disclosure-container">
      <h2>Research Services Disclosure</h2>
      {sections.map((section, index) => (
        <div key={index} className="disclosure-section">
          <button
            className="section-title"
            onClick={() => toggleSection(index)}
          >
            {section.title}
          </button>
          {activeIndex === index && <div className="section-content">{section.content}</div>}
        </div>
      ))}
    </div>
  );
};

export default DisclosureComponent;
