import React from 'react';
import { Card } from 'react-bootstrap';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaClock } from 'react-icons/fa';
import './AddressCard.css'; // Custom CSS for styling

const AddressCard = () => {
  return (
    <Card className="address-card shadow-lg p-4">
      <Card.Body>
        <div className="address-section">
          <h5><FaMapMarkerAlt className="icon" /> Address</h5>
          <p>Mumbai</p>
        </div>

        <div className="contact-section">
          <h5><FaPhoneAlt className="icon" /> Get in touch</h5>
          <p>+91 93242 62969</p>
        </div>

        <div className="email-section">
          <h5><FaEnvelope className="icon" /> Email us at</h5>
          <p>tradeace@gmail.com</p>
          <p>salestradeace@gmail.com</p>
        </div>

        <div className="hours-section">
          <h5><FaClock className="icon" /> Monday to Friday</h5>
          <p>09:00 to 06:00 PM</p>
        </div>


        <div className="hours-section">
          <h5><FaClock className="icon" /> Saturday</h5>
          <p>09:00 to 06:00 PM</p>
        </div>

        <div className="hours-section">
          <h5><FaClock className="icon" /> Sunday closed</h5>
          
        </div>
      </Card.Body>
    </Card>
  );
};

export default AddressCard;
