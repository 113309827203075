// src/styles/colors.js
const colors = {
    primary: "#2f2f80",    // Example of a primary color
    secondary: "#2ecc71",  // Example of a secondary color
    accent: "#e74c3c",     // Example of an accent color
    background: "#f5f5f5", // Example of a background color
    text: "#333333",       // Example of a text color
    white: "#ffffff",      // Example of a white color
    black: "#000000",      // Example of a black color
    grey: "#95a5a6",       // Example of a grey color
  };
  
  export default colors;
  