import { useNavigate } from 'react-router-dom';
import './FloatingComplaintButton.css';

function FloatingComplaintButton() {
    const navigate = useNavigate();  // Initialize the navigate function

    const handleClick = () => {
        // Navigate to the desired route (e.g., "/complaint-board")
        navigate('/complaint-board');
    };

    return (
        <div className="floating-button">
            <button onClick={handleClick}>Complaint Board</button>
        </div>
    );
}

export default FloatingComplaintButton;
