import React from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';

const InvestmentCharter = () => {
  return (
    <Container fluid className="investment-charter-container py-5">
      <Row>
        <Col xs={12} className="text-center mb-4">
          <h1 className="display-4">Investment Charter</h1>
        </Col>
      </Row>

      {/* Vision and Mission in Two Columns */}
      <Row className="mb-5">
        {/* Vision Column */}
        <Col xs={12} md={6} className="mb-4 mb-md-0">
          <h3 className="mb-3">Vision</h3>
          <p className="text-justify">
            Invest with knowledge & safety.
          </p>
        </Col>

        {/* Mission Column */}
        <Col xs={12} md={6}>
          <h3 className="mb-3">Mission</h3>
          <p className="text-justify">
            Every investor should be able to invest in the right investment products based on their needs, manage and monitor them to meet their goals, access reports, and enjoy financial wellness.
          </p>
        </Col>
      </Row>

      {/* Details of Business Transacted */}
      <Row className="mb-5">
        <Col xs={12}>
          <h3 className="mb-4">Details of Business Transacted</h3>
          <ul className="pl-4">
            <li>To publish research report based on the research activities of the RA.</li>
            <li>To provide an independent unbiased view on securities.</li>
            <li>To offer unbiased recommendations, disclosing the financial interests in recommended securities.</li>
            <li>To provide research recommendations based on analysis of publicly available information and known observations.</li>
            <li>To conduct an annual audit.</li>
          </ul>
        </Col>
      </Row>

      {/* Services Provided to Investors */}
      <Row className="mb-5">
        <Col xs={12}>
          <h3 className="mb-4">Details of Services Provided to Investors (No Indicative Timelines)</h3>
          <ul className="pl-4">
            <li>Onboarding of Clients</li>
            <li>Disclosure to Clients</li>
            <li>Distribute research reports and recommendations to the clients without discrimination.</li>
            <li>Maintain confidentiality with respect to the publication of the research report until made available in the public domain.</li>
          </ul>
        </Col>
      </Row>

      {/* Grievance Redressal Mechanism */}
      <Row className="mb-5">
        <Col xs={12}>
          <h3 className="mb-4">Grievance Redressal Mechanism</h3>
          <p>
            - In case of any grievance/complaint, an investor should approach the concerned research analyst and ensure that the grievance is resolved within 30 days.
            <br />
            - If the investor’s complaint is not redressed satisfactorily, they can lodge a complaint with SEBI on SEBI’s SCORES portal. SCORES facilitates tracking the status of the complaint.
            <br />
            - For physical complaints, investors may send their complaints to:
            <br />
            <strong>Office of Investor Assistance and Education</strong>
            <br />
            SEBI Bhavan, Plot No. C4-A, ‘G’ Block, Bandra-Kurla Complex, Bandra (E), Mumbai – 400 051.
          </p>
        </Col>
      </Row>

      {/* Expectations from Investors */}
      <Row className="mb-5">
        <Col xs={12}>
          <h3 className="mb-4">Expectations from the Investors</h3>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Do's</Accordion.Header>
              <Accordion.Body>
                <ul className="pl-4">
                  <li>Always deal with SEBI registered Research Analysts.</li>
                  <li>Ensure that the Research Analyst has a valid registration certificate.</li>
                  <li>Check for SEBI registration number.</li>
                  <li>Always pay attention to disclosures made in the research reports before investing.</li>
                  <li>Pay your Research Analyst through banking channels only and maintain duly signed receipts.</li>
                  <li>Before buying securities or applying in public offers, check for the research recommendation provided by your Research Analyst.</li>
                  <li>Ask all relevant questions and clear your doubts with your Research Analyst before acting on the recommendation.</li>
                  <li>Inform SEBI about Research Analysts offering assured or guaranteed returns.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Don'ts</Accordion.Header>
              <Accordion.Body>
                <ul className="pl-4">
                  <li>Do not provide funds for investment to the Research Analyst.</li>
                  <li>Don’t fall prey to luring advertisements or market rumors.</li>
                  <li>Do not get attracted to limited period discounts or other incentives, gifts, etc.</li>
                  <li>Do not share login credentials and password of your trading and demat accounts with the Research Analyst.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default InvestmentCharter;
