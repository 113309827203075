import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FiPhoneCall } from 'react-icons/fi';  // Phone Icon from Feather Icons
import { MdVerified } from 'react-icons/md';   // SEBI Registered Icon from Material Design Icons
import { AiOutlineCheckCircle } from 'react-icons/ai'; // Check Circle from Ant Design Icons
import './TradeAceServices.css'; // Import custom CSS for styling
import '../colors/colors.css'

const TradeAceServices = () => {
  return (
    <Container fluid className="py-5 text-center">
      <h2 className="mb-5">Why Choose TradeAce Services?</h2>
      <Row className="justify-content-center">
        <Col md={4} className="mb-4">
          <Card className="shadow-lg">
            <Card.Body>
              <div className="icon-wrapper mb-3">
                <FiPhoneCall size={40} style={{ color: '#fff' }} />
              </div>
              <Card.Title>Complete Support on Daily Basis</Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="shadow-lg">
            <Card.Body>
              <div className="icon-wrapper mb-3">
                <MdVerified size={40} style={{ color: '#fff' }} />
              </div>
              <Card.Title>SEBI Registered</Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="shadow-lg">
            <Card.Body>
              <div className="icon-wrapper mb-3">
                <AiOutlineCheckCircle size={40} style={{ color: '#fff' }} />
              </div>
              <Card.Title>Risk Managed Calls with Target and Stop Loss</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TradeAceServices;
