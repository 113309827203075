import React, { useState } from "react";
import "./GrievanceRedressalComponent.css";

const GrievanceRedressalComponent = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleSection = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const sections = [
    {
      title: "Grievance Redressal Policy Principles",
      content: (
        <>
          <ul>
            <li>Investors will be treated fairly at all times.</li>
            <li>Complaints raised by Investors will be dealt with courtesy and in a timely manner.</li>
          </ul>
        </>
      ),
    },
    {
      title: "Client Servicing Team",
      content: (
        <>
          We have a dedicated Client Servicing Team which is responsible for timely
          and prompt communication with our clients, while having an open attitude
          towards service recovery and providing alternate solutions to investors,
          thus ensuring healthy relationships with our clients.
          <br />
          <strong>Contact Email:</strong> <a href="mailto:compliance@tradeace.in">compliance@tradeace.in</a>
        </>
      ),
    },
    {
      title: "Grievance Redressal Mechanism",
      content: (
        <>
          Client’s queries/complaints may arise due to lack of understanding or a
          deficiency of service experienced by clients. Deficiency of service may
          include lack of explanation, clarifications.
          <br />
          <h6>1. Call Customer Care:</h6>
          Clients can call our customer care team, available from Mon-Sat 9am to 6pm.
          <br />
          <strong>Contact No:</strong> 02244744000 – Client can expect the reply to
          their concern within 24 to 48 working hours.
          <br />
          <h6>2. Write to Client Servicing Team:</h6>
          You may write to <a href="mailto:compliance@tradeace.in">compliance@tradeace.in</a> 
          and expect a response within 10 business days after approaching the Client Servicing Team.
          <br />
          <h6>3. SEBI Grievance Filing:</h6>
          If you're not satisfied with our response, you can lodge your grievance with SEBI at
          <br />
          <a href="https://scores.sebi.gov.in/" target="_blank" rel="noopener noreferrer">https://scores.sebi.gov.in/</a>
          or you may also write to any of the SEBI offices.
          <br />
          <h6>4. SmartODR Grievance Filing:</h6>
          You can also lodge your grievance at
          <br />
          <a href="https://smartodr.in/" target="_blank" rel="noopener noreferrer">https://smartodr.in/</a>
        </>
      ),
    },
    {
      title: "Compliance Officer",
      content: (
        <>
          <strong>Name:</strong> Sameer Satish Mekde
          <br />
          <strong>Contact Details:</strong> 7506403201
          <br />
          <strong>Email:</strong> <a href="mailto:info@sameermekde.com">info@sameermekde.com</a>
        </>
      ),
    },
    {
      title: "Grievance Officer",
      content: (
        <>
          <strong>Name:</strong> Sameer Satish Mekde
          <br />
          <strong>Contact Details:</strong> 7506403201
          <br />
          <strong>Email:</strong> <a href="mailto:info@sameermekde.com">info@sameermekde.com</a>
        </>
      ),
    },
  ];

  return (
    <div className="grievance-container">
      <h2>Grievance Redressal Process</h2>
      {sections.map((section, index) => (
        <div key={index} className="grievance-section">
          <button
            className="section-title"
            onClick={() => toggleSection(index)}
          >
            {section.title}
          </button>
          {activeIndex === index && <div className="section-content">{section.content}</div>}
        </div>
      ))}
    </div>
  );
};

export default GrievanceRedressalComponent;
